/* You can add global styles to this file, and also import other style files */

@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&display=swap");
@font-face {
  font-family: "CircularStd";
  src: url("assets/fonts/CircularStd-Black.eot");
  src: url("assets/fonts/CircularStd-Black.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/CircularStd-Black.woff") format("woff"),
    url("assets/fonts/CircularStd-Black.ttf") format("truetype"),
    url("assets/fonts/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd";
  src: url("assets/fonts/CircularStd-Bold.eot");
  src: url("assets/fonts/CircularStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/CircularStd-Bold.woff") format("woff"),
    url("assets/fonts/CircularStd-Bold.ttf") format("truetype"),
    url("assets/fonts/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "Open Sans", Arial, sans-serif;
  background: #ffffff;
  color: #000000;
  font-size: 14px;
}

h1,
.h1 {
  font-size: 30px;
}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

textarea {
  resize: none;
}

.link-dark a,
a.link-dark {
  color: #333333;
}

.link-red a,
a.link-red {
  color: #f4554a;
}

.btn.btn-danger {
  background-color: #f4554a;
  border-color: #f4554a;
}

.bg-light-blue {
  background-color: #d2f5fa;
}

.text-green {
  color: #8356f7;
}

.bg-grey {
  background-color: #e8e8e8;
}

a.embed-video-icon {
  position: relative;
}

a.embed-video-icon:before {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  display: block;
  content: "";
  border-radius: 80px;
}

.doc-form-sec [data-toggle="collapse"] .fas:before {
  content: "\f0da";
}

.doc-form-sec [data-toggle="collapse"].collapsed .fas:before {
  content: "\f0d7";
}

.doc-form-sec .doc-bg-title {
  position: relative;
}

.doc-form-sec .doc-bg-title i.fas.fa-caret-right {
  position: absolute;
  top: 3px;
  font-size: 24px;
  left: 6px;
}

.doc-form-sec .doc-bg-title a {
  text-decoration: none;
  padding-left: 20px;
}

.doc-form-sec .doc-bg-title {
  background: rgb(80, 255, 185);
  background: -moz-linear-gradient(
    0deg,
    rgba(80, 255, 185, 1) 0%,
    rgba(59, 191, 138, 1) 50%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(80, 255, 185, 1) 0%,
    rgba(59, 191, 138, 1) 50%
  );
  background: linear-gradient(
    0deg,
    rgba(80, 255, 185, 1) 0%,
    rgba(59, 191, 138, 1) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#50ffb9", endColorstr="#3bbf8a", GradientType=1);
  padding: 5px;
  font-weight: 600;
  margin-bottom: 2px;
}

.form-control.disabled.h-auto {
  background-color: #e9ecef;
}



.notification-info {
  background-color: #d4c5fc;
}

.notification-warning {
  background-color: #ffe7c2;
}

.btn.btn-custom {
  border-radius: 2px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-family: "CircularStd";
  background-color: #1f2041;
  color: white;
}

.btn.btn-syntax {
  border-radius: 2px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-family: "CircularStd";
  background-color: #ffe7c2;
}

.btn.btn-syntax:hover {
  background-color: #fecd83;
}

.btn.btn-custom:hover {
  background-color: #070715;
  color:white;
}

span.bg-form-text {
  background-color: #b5e5d3;
  border-bottom: 4px #333333 solid;
  border-radius: 4px 4px 0px 0px;
}

.tag-box span.tag-bg-title {
  background-color: #b5e5d3;
  padding: 2px 10px;
}

.tag-box {
  display: inline-block;
}

.tag-button {
  background-color: #b5e5d3;
  outline: 0 none;
  border: 0 none;
  box-shadow: none;
  padding: 2px 10px;
  margin-bottom: 10px;
}

.tag-button.active {
  opacity: 0.5;
}

@media screen and (max-width: 767px) {
}

@media (min-width: 768px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Customize the label (the container) */
.form-check-inline-radio {
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.form-check-inline-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  text-align: center;
  font-weight: bold;
}

.form-check-inline-radio label {
  width: 100%;
  padding: 5px;
}

/* On mouse-over, add a grey background color */
.form-check-inline-radio:hover label {
  background-color: #ccc;
  border-radius: 4px 4px 0px 0px;
}

/* When the radio button is checked, add a blue background */
.form-check-inline-radio input:checked ~ label {
  background-color: #d4c5fc;
  border-bottom: 4px #333333 solid;
  border-radius: 4px 4px 0px 0px;
}

ng-dropdown-panel {
  width: auto !important;
}

.mat-expansion-panel-body {
  padding-left: 5px !important;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.mat-tooltip {
  font-size: 12px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-style: none !important;
  border-width: 0 !important;
}
.ng-select .ng-arrow-wrapper {
  background-image: url('/assets/img/down-arrow.png');
  background-repeat: no-repeat;
  background-size: 19px 19px;
  margin-top: 2px;
}

.ng-select .ng-select-container{
  border-color: #a6a6a6 !important;
}

body .ui-multiselect-panel .pi-times{
  display:none;
}

/**
 * Custom cross-browser styles for keyboard :focus overrides defaults.
 */


/**
 * Remove focus styles for non-keyboard :focus.
 */
*:focus:not(.focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

.ui-dropdown-label-container{
 overflow-x: hidden;
}
.ui-tooltip{
  overflow-wrap: anywhere;
}

p-dropdown{
  display: grid;
}

.variable-item{ overflow-wrap: anywhere;
  display: inline-block;
  width: 80%;
}

/*DP-4345*/
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  background-color: #D4C4FC !important;
  color:black !important;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight{
  background-color: #D4C4FC !important;
  color:black !important;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight{
background-color: #D4C4FC !important;
  color:black !important;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error){
  border-color: #D4C4FC !important;
  box-shadow: 0 0 0 0.2em #8356f7 !important;
}

body .ui-dropdown:not(.ui-state-error).ui-state-focus{
  border-color: #D4C4FC !important;
  box-shadow: 0 0 0 0.2em #8356f7 !important;
}

/*DP-4345*/
.form-control:focus{
  border-color: #D4C4FC !important;
  box-shadow: 0 0 0 0.2em #8356f7 !important;
}

.ng-select.ng-select-focused{
  border-color: #D4C4FC !important;
  box-shadow: 0 0 0 0.2em #8356f7 !important;
  border-radius: 5px !important;
}

.ng-select:focus {
  border-color: #D4C4FC !important;
  box-shadow: 0 0 0 0.2em #8356f7 !important;
  border-radius:5px !important;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active{
  border-color: #D4C4FC !important;
  background-color:#8356f7 !important;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus{
  box-shadow: inset 0 0 0 0.2em #D4C4FC !important;
}
